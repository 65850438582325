export const reportRoutes = [
  {
    path: "/reports/receive",
    name: "ReceiveReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/ReceiveReport.vue"
      ),
    meta: {
      title: "Receives Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/transfer",
    name: "TransferReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/TransferReport.vue"
      ),
    meta: {
      title: "Transfers Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/requisitions/:reference?",
    name: "RequisitionsReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/RequisitionsReport.vue"
      ),
    meta: {
      title: "Requisitions Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/stock-status",
    name: "TrackerReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/TrackerReport.vue"
      ),
    meta: {
      title: "Stock Status Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/adjustments",
    name: "AdjustmentsReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/AdjustmentsReport.vue"
      ),
    meta: {
      title: "Adjustments Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/expired-products",
    name: "ExpiredProducts",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/ExpiredProducts.vue"
      ),
    meta: {
      title: "Expired Products",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/sales",
    name: "SalesReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Sales Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/sales/items",
    name: "DetailsReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/DetailsReport.vue"
      ),
    meta: {
      title: "Details Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/clients/balances",
    name: "CustomerBalanceReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/CustomerBalance.vue"
      ),
    meta: {
      title: "Customer Balances Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/payment-history",
    name: "PaymentHistoryReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/PaymentHistoryReport.vue"
      ),
    meta: {
      title: "Payment Received Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/account/transactions",
    name: "AccountTransactions",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/AccountTransactions.vue"
      ),
    meta: {
      title: "Account Transactions Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/accounts/transfers",
    name: "AccountsTransfers",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/AccountTransfers.vue"
      ),
    meta: {
      title: "Accounts Transfers Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/finance/expenses",
    name: "ExpensesReport",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Finance/Expenses.vue"
      ),
    meta: {
      title: "Expenses Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/account/transactions",
    name: "AccountTransactions",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/AccountTransactions.vue"
      ),
    meta: {
      title: "Account Transactions Report",
      requiredAuth: true,
    },
  },
  {
    path: "/reports/accounts/transfers",
    name: "AccountsTransfers",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/AccountTransfers.vue"
      ),
    meta: {
      title: "Accounts Transfers Report",
      requiredAuth: true,
    },
  },{
    path: "/reports/waiters-performance-report",
    name: "WaitersPerformance",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Reports/WaitersPerformance.vue"
      ),
    meta: {
      title: "Waiters Performance Report",
      requiredAuth: true,
    },
  },
];

export const globalMixin = {
  data: () => ({
    modalOpen: false,
    filterKey: null,
    publicPath: process.env.BASE_URL,
    permissionsPath: null,
  }),
  computed: {
    userPermissions() {
      if (this.$store.getters.user) {
        const role = this.$store.getters.user.role;
        if (role) return role.permissions;
      }
      return {};
    },
    appSettings() {
      const settings = this.$store.state.company || {};
      return settings;
    },
    loggedUser() {
      return this.$store.getters.user || {};
    },
    userRole() {
      if (this.loggedUser.role) return this.loggedUser.role.name;
      else return null;
    },
  },
  methods: {
    handleLogout() {
      this.$http
        .post("auth/logout", this.$helper.generateFormData({}))
        .then((response) => {
          if (response.data.status) {
            this.$auth.logout();
          }
        });
    },
    toggleModal() {
      this.$helper.toggleModalOpen();
      this.modalOpen = !this.modalOpen;
    },
    formatMoney(num) {
      return `RWF ${this.$helper.formatNumber(num)}`;
    },
    getPaymentsMeta() {
      this.$store.commit("SET_AJAX_TASK", "GET_PAYMENTS_META");
      this.$http.get("shared/payments-meta").then((response) => {
        this.$store.commit("SET_PAYMENT_META", response.data);
      });
    },
    handleSideBar() {
      const m = document.body.getAttribute("data-sidebar-size");
      document.body.classList.toggle("sidebar-enable");
      if (m == "lg") document.body.setAttribute("data-sidebar-size", "sm");
      else document.body.setAttribute("data-sidebar-size", "lg");
    },
  },
};

import { createStore } from "vuex";

export default createStore({
  state: {
    user: {},
    company:{},
    isLoading: false,
    loadingTask: null,
    requestPercentage: 0,
    flashMessage: null,
    selectedCompany: JSON.parse(localStorage.getItem("_company")) || null,
    companies: [],
    routes: {},
  },
  getters: {
    user: (state) => state.user || {},
  },
  mutations: {
    SET_PAYMENT_META(state, data) {
      state.accounts = data.accounts;
      state.paymentModes = data.modes;
    },
    SET_CURRENT_USER(state, data) {
      state.user = data.user;
      state.company = data.company;
    },
    SET_AJAX_TASK(state, task) {
      state.loadingTask = task;
    },
    SET_FLASH_MESSAGE(state, message) {
      state.flashMessage = message;
    },
    REQUEST_RESOLVED(state) {
      state.isLoading = false;
      state.loadingTask = null;
      if (state.hasActiveModal) {
        state.hasActiveModal = false;
        document.querySelector("#alert-container").remove();
      }
    },
    REGISTER_CONFIRM_MODAL(state) {
      state.loadingTask = "HANDLING_ALERT_ACTION";
      state.hasActiveModal = true;
    },
  },
  actions: {},
  modules: {},
});

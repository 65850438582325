import { createRouter, createWebHistory } from "vue-router";
import { posRoutes } from "./pos.routes";
import { reportRoutes } from "./reports.routes";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/index/views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Dashboard/Index.vue"
      ),
    meta: {
      title: "Dashboard",
      requiredAuth: true,
    },
  },
  {
    path: "/leads/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/index/views/Leads/Users.vue"),
    meta: {
      title: "Users",
      requiredAuth: true,
    },
  },
  {
    path: "/leads/suppliers",
    name: "Suppliers",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Leads/Suppliers.vue"
      ),
    meta: {
      title: "Suppliers",
      requiredAuth: true,
    },
  },
  {
    path: "/leads/clients",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/index/views/Leads/Clients.vue"),
    meta: {
      title: "Clients",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/categories",
    name: "Categories",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Inventory/Categories.vue"
      ),
    meta: {
      title: "Categories",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/products",
    name: "Products",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Inventory/Products.vue"
      ),
    meta: {
      title: "Products",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/receive/:action/:reference?",
    name: "ItemsReceive",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Inventory/ItemsReceive.vue"
      ),
    meta: {
      title: "Receive items from supplier",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/transfers/:action/:reference?",
    name: "ItemsTransfer",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Inventory/ItemsTransfer.vue"
      ),
    meta: {
      title: "Transfer items to departments",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/requisitions/:action/:reference?",
    name: "RequisitionCreator",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Inventory/RequisitionCreator.vue"
      ),
    meta: {
      title: "Create Requisitions",
      requiredAuth: true,
    },
  },
  {
    path: "/inventory/adjustments/:action/:reference?",
    name: "Adjustments",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Inventory/Adjustments.vue"
      ),
    meta: {
      title: "Adjustments",
      requiredAuth: true,
    },
  },
  {
    path: "/setings/ItemsUnits",
    name: "ItemsUnits",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Settings/Units.vue"
      ),
    meta: {
      title: "Items Units",
      requiredAuth: true,
    },
  },
  {
    path: "/setings/general",
    name: "GeneralSettings",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Settings/GeneralSettings.vue"
      ),
    meta: {
      title: "General Settings",
      requiredAuth: true,
    },
  },
  {
    path: "/setings/departments",
    name: "Departments",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Settings/Departments.vue"
      ),
    meta: {
      title: "Departments",
      requiredAuth: true,
    },
  },
  {
    path: "/setings/user-roles",
    name: "UserRoles",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Settings/UserRoles.vue"
      ),
    meta: {
      title: "User and roles",
      requiredAuth: true,
    },
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/index/views/UserProfile.vue"),
    meta: {
      title: "User Profile",
      requiredAuth: true,
    },
  },
  {
    path: "/point-of-sale/tables",
    name: "Postables",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/index/views/POS/Tables.vue"),
    meta: {
      title: "POS Tables",
      requiredAuth: true,
    },
  },
  {
    path: "/finance/accounts",
    name: "FinanceAccounts",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Finance/Accounts.vue"
      ),
    meta: {
      title: "Accounts",
      requiredAuth: true,
    },
  },
  {
    path: "/finance/expenses/:action?/:reference?",
    name: "FinanceExpenses",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Finance/ExpenseCreator.vue"
      ),
    meta: {
      title: "Expenses",
      requiredAuth: true,
    },
  },
  {
    path: "/finance/expenses/categories",
    name: "FinanceExpenseCategories",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Finance/ExpenseCategories.vue"
      ),
    meta: {
      title: "Expense's Categories",
      requiredAuth: true,
    },
  },
  {
    path: "/finance/payments/suppliers",
    name: "FinanceSupplierPayments",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Finance/SupplierPayments.vue"
      ),
    meta: {
      title: "Supplier Payments",
      requiredAuth: true,
    },
  },
  {
    path: "/finance/invoices/bulk-payment",
    name: "InvoicesBulkPayment",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Finance/InvoicesBulkPayment.vue"
      ),
    meta: {
      title: "Bulk Invoices Payment",
      requiredAuth: true,
    },
  },
  {
    path: "/assets",
    name: "AssetsList",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/index/views/Assets/Assets.vue"),
    meta: {
      title: "Assets List",
      requiredAuth: true,
    },
  },
  {
    path: "/assets/categories",
    name: "AssetCategories",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/index/views/Assets/Categories.vue"
      ),
    meta: {
      title: "Assets Categories",
      requiredAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes, ...posRoutes, ...reportRoutes],
});

const token = localStorage.getItem("token");
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (token) {
      next();
      return;
    }
    location.replace("/");
  } else if (to.matched.some((record) => record.meta.requireFrontOfficeAuth)) {
    if (token) {
      next();
      return;
    }
    window.location.replace("/pos/login");
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router;

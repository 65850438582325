export default {
  install: (app) => {
    app.config.globalProperties.$auth = {
      setToken(data) {
        localStorage.setItem("token", data.access_token);
      },
      active() {
        return (
          localStorage.getItem("token") || localStorage.getItem("__front_token")
        );
      },
      logout() {
        localStorage.removeItem("token");
        const isFrontOffice = location.pathname.indexOf("/pos/") !== -1;
        if (isFrontOffice) {
          window.location.replace("/pos/login");
        } else {
          location.replace("/");
        }
      },
    };
  },
};
